import API_ENDPOINT from "../constants/api_endpoint.constant";
import axios from '../utils/axiosService';
import { getToken } from '../utils/token';

axios.setHeader("Authorization", `Bearer ${getToken()}`);
axios.setHeader("Content-Type", `application/json`);

export default class CrepicoPaymentService {
    
    searchCrepicoPaymentData(merchantNo, terminalIdentification, paymentStatus, paymentTypes, transactionCategory, fromDate, toDate, fromSaleAmount, toSaleAmount, page, pageSize, sortValue, sortDirectionValue) {
        return axios.get(`${API_ENDPOINT.AGX_CREPICO_PAYMENT_DATA}/search?merchantNo=${merchantNo}&terminalIdentification=${terminalIdentification}&paymentStatus=${paymentStatus}&paymentTypes=${paymentTypes}&transactionCategories=${transactionCategory}&fromDate=${fromDate}&toDate=${toDate}&fromSaleAmount=${fromSaleAmount}&toSaleAmount=${toSaleAmount}&page=${page}&pageSize=${pageSize}&sortValue=${sortValue}&sortDirection=${sortDirectionValue}`);
    };
    
    fetchAllCrepicoPaymentData(merchantNo, sortValue, sortDirectionValue) {
        return axios.get(`${API_ENDPOINT.AGX_CREPICO_PAYMENT_DATA}/fetch-all?merchantNo=${merchantNo}&sortValue=${sortValue}&sortDirection=${sortDirectionValue}`);
    };
}