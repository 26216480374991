import React from "react";
import "../index.css";
import logoIndex02 from '../../../../utils/image/logo_index02.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Footer() {

    return (
        <footer style={{marginTop: '-60px'}}>
                <div className="footer-contact">
                    <div className="menuArea01">
                        <div className="container">
                            <section className="menuContent01">
                                <ul className="menuList01">
                                    <li><a href="https://choqi.co.jp/index.html#company" className="smoothScroll">COMPANY</a></li>
                                    <li><a href="/contact" className="smoothScroll"><FontAwesomeIcon icon={faEnvelope} /> CONTACT</a></li>
                                </ul>
                                <div className="footer-logo"><a href="../"><img className="footerLogo01" src={logoIndex02} alt="ChoQi" /></a></div>
                            </section>
                        </div>
                    </div>
                    <div className="footerArea01">
                        <div className="container">
                            <div className="footerContent01">
                                <div className="footerBox01 box01">
                                    <p><a href="https://choqi.co.jp/privacypolicy.html">プライバシーポリシー</a></p>
                                    <p><a href="https://choqi.co.jp/choqipay/rule.pdf">利用規約</a></p>
                                </div>
                                <div className="footerBox01">
                                    <p>© ChoQi Co., Ltd.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
    );
}

export default Footer;