import React from 'react';
import { Link } from 'react-router-dom';
import '../index.css';
import { useSelector } from 'react-redux'
import useFetch from '../../../../../../hooks/useFetch';
import API_ENDPOINT from "../../../../../../constants/api_endpoint.constant";
import { authSelector } from '../../../../../../redux/auth/selector';
import crepico_privacy1 from '../../../../../../utils/pdf/クレピコ端末設置使用規約（クレジット編）.pdf';
import crepico_privacy2 from '../../../../../../utils/pdf/クレピコ端末保守サービス規約.pdf';
import crepico_privacy3 from '../../../../../../utils/pdf/クレピコサービス加入規約.pdf';
import { getUrlRules } from '../../../../../../constants/common.constant';

function RulesCrepico() {
    const { agxMerchantNo, user, agxNewMerchantNo, agxOldMerchantNo } = useSelector(authSelector);
    const { data } = useFetch(`${API_ENDPOINT.NOTIFICATION_RULES}?merchantNo=${btoa(user?.memberType ? agxMerchantNo : agxNewMerchantNo)}`);
    return (
        <div className="wrapper-body">
            <div className="container">
                <div className="page-heading">
                    <ul className="breadcrumb">
                        <li>
                            <Link to="/store" title="チョキペイ">チョキペイ</Link>
                        </li>
                        <li>
                            <Link to="/store-notification" title="その他">その他</Link>
                        </li>
                        {
                            agxNewMerchantNo &&
                            <li>
                                <Link to={getUrlRules(user, agxNewMerchantNo, agxOldMerchantNo)} title="各種規約">各種規約</Link>
                            </li>
                        }
                        <li>ストアの選択</li>
                    </ul>
                </div>
                <div className="row" style={{ paddingLeft: '20px', display: 'block' }}>
                    <div style={{ marginBottom: '20px', marginTop: '30px', textDecoration: 'underline 1px', textUnderlineOffset: '1em' }}>
                        <p style={{ fontSize: '24px', paddingLeft: '10px' }}>各種規約</p>
                    </div>
                    <div style={{ marginBottom: '30px' }}>
                        <p style={{ fontSize: '24px' }}>各種サービスの規約を掲載しております。リンクをクリックしてご覧ください。</p>
                    </div>
                    <div style={{ marginBottom: '4px', paddingLeft: '10px' }}>
                        <div className="form-group rules-p-a">
                            <p style={{ fontSize: '20px' }}><a href="https://choqi.co.jp/choqipay/rule.pdf" target="_href">チョキペイ加盟店利用規約</a></p>
                        </div>
                        <div className="form-group rules-p-a">
                            <p style={{ fontSize: '20px' }}><a href="https://www.cr.mufg.jp/merchant/rule/mufgcard/shop_01.pdf" target="_href">MUFGカード加盟店規約</a></p>
                        </div>
                        <div className="form-group rules-p-a">
                            <p style={{ fontSize: '20px' }}><a href="https://www.jcb.co.jp/kiyaku/pdf/kameiten0705_05.pdf" target="_href">JCB加盟店規約</a></p>
                        </div>
                        <div className="form-group rules-p-a">
                            <p style={{ fontSize: '20px' }}><a href="https://www.jcb.co.jp/kiyaku/pdf/premo_kameiten.pdf" target="_href">JCB PREMO取扱加盟店特約 </a></p>
                        </div>
                        <div className="form-group rules-p-a">
                            <p style={{ fontSize: '20px' }}><a href="https://www.jcb.co.jp/kiyaku/pdf/tanako_kameiten.pdf" target="_href">店子加盟店特約（店頭通販共通）</a></p>
                        </div>
                        <div className="form-group rules-p-a">
                            <p style={{ fontSize: '20px' }}><a href="https://www.cr.mufg.jp/merchant/rule/ginrei/shop.pdf" target="_href">銀聯カード加盟店規約</a></p>
                        </div>
                        <div className="form-group rules-p-a" style={{ display: data?.settlementPackage2 ? 'block' : 'none' }}>
                            <p style={{ fontSize: '20px' }}><a href="https://www.cr.mufg.jp/merchant/rule/index_01.pdf" target="_href">三菱UFJニコス－交通系電子マネー加盟店規約</a></p>
                        </div>
                        <div className="form-group rules-p-a" style={{ display: data?.settlementPackage2 ? 'block' : 'none' }}>
                            <p style={{ fontSize: '20px' }}><a href="https://www.cr.mufg.jp/merchant/rule/e_money/nanaco.pdf" target="_href">三菱UFJニコス－nanaco電子マネー加盟店規約</a></p>
                        </div>
                        <div className="form-group rules-p-a" style={{ display: data?.settlementPackage2 ? 'block' : 'none' }}>
                            <p style={{ fontSize: '20px' }}><a href="https://www.cr.mufg.jp/merchant/rule/e_money/waon.pdf" target="_href">三菱UFJニコス－WAON加盟店規約</a></p>
                        </div>
                        <div className="form-group rules-p-a" style={{ display: data?.settlementPackage2 ? 'block' : 'none' }}>
                            <p style={{ fontSize: '20px' }}><a href="https://www.cr.mufg.jp/merchant/rule/e_money/edy.pdf" target="_href">三菱UFJニコス－Edy間接加盟店規約</a></p>
                        </div>
                        <div className="form-group rules-p-a" style={{ display: data?.settlementPackage2 ? 'block' : 'none' }}>
                            <p style={{ fontSize: '20px' }}><a href="https://www.cr.mufg.jp/merchant/rule/e_money/id.pdf" target="_href">三菱UFJニコス－ｉＤ加盟店規約</a></p>
                        </div>
                        <div className="form-group rules-p-a">
                            <p style={{ fontSize: '20px' }}><a href="https://www.daiwahousefinancial.co.jp/docs/terms/omatome-terms/" target="_href">QRコード決済規約</a></p>
                        </div>
                        <div className="form-group rules-p-a">
                            <p style={{ fontSize: '20px' }}><a href={crepico_privacy1} target='_blank'>クレピコ端末設置使用規約（クレジット編）</a></p>
                        </div>
                        <div className="form-group rules-p-a">
                            <p style={{ fontSize: '20px' }}><a href={crepico_privacy2} target='_blank'>クレピコ端末保守サービス規約</a></p>
                        </div>
                        <div className="form-group rules-p-a">
                            <p style={{ fontSize: '20px' }}><a href={crepico_privacy3} target='_blank'>クレピコサービス加入規約</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RulesCrepico;