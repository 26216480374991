import React, { Suspense, lazy, Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { DefaultLayout, ContactLayout } from './components/Layout/index';
import ProtectedRoute from './ProtectedRoute';
import {
    ADMIN,
    STORE,
    APPLICATION,
    APPLICATION_STEPS,
    APPLICATION_COMPLETE,
    APPLICATION_CANCEL,
    APPLICATION_ACCEPTED_CORRECTIONS,
    APPLICATION_REJECTED
} from './constants/common.constant';
import Support from "./components/Body/Store/Support";
import InvoicePaygate from "./components/Body/Store/InvoiceReceipt/Paygate/Invoice";
import InvoiceCrepico from "./components/Body/Store/InvoiceReceipt/Crepico/Invoice";
import ReceiptPaygate from "./components/Body/Store/InvoiceReceipt/Paygate/Receipt";
import ReceiptCrepico from "./components/Body/Store/InvoiceReceipt/Crepico/Receipt";
import Rollpaper from "./components/Body/Store/Notification/rollpaper";
import Faq from "./components/Body/Store/Notification/faq";
import RulesCrepico from "./components/Body/Store/Notification/Rules/Crepico";
import RulesPaygate from "./components/Body/Store/Notification/Rules/Paygate";
const DepositDetailAllCrepico = lazy(() => import("./components/Body/Store/Deposit/Crepico/DepositDetailAll"));
const DepositDetailAllPaygate = lazy(() => import("./components/Body/Store/Deposit/Paygate/DepositDetailAll"));
const DepositDetailPaygate = lazy(() => import("./components/Body/Store/Deposit/Paygate/DepositDetail"));
const DepositDetailCrepico = lazy(() => import("./components/Body/Store/Deposit/Crepico/DepositDetail"));
const InvoiceMonthlyPaygate = lazy(() => import("./components/Body/Store/InvoiceReceipt/Paygate/InvoiceMonthly"));
const InvoiceMonthlyCrepico = lazy(() => import("./components/Body/Store/InvoiceReceipt/Crepico/InvoiceMonthly"));
const ReceiptMonthlyCrepico = lazy(() => import("./components/Body/Store/InvoiceReceipt/Crepico/ReceiptMonthly"));
const ReceiptMonthlyPaygate = lazy(() => import("./components/Body/Store/InvoiceReceipt/Paygate/ReceiptMonthly"));
const StoreCrepicoPayment = lazy(() => import("./components/Body/Store/CrepicoPayment"));
const AdminStoreCrepicoPayment = lazy(() => import("./components/Body/AdminStore/CrepicoPayment"));
const CheckDeposit = lazy(() => import("./components/Body/Store/Deposit/checkDeposit"));
const CheckInvoiceReceipt = lazy(() => import("./components/Body/Store/InvoiceReceipt/checkInvoiceReceipt"));
const SummaryMonthlyPaygate = lazy(() => import("./components/Body/Store/SummaryMonthly/Paygate"));
const SummaryMonthlyCrepico = lazy(() => import("./components/Body/Store/SummaryMonthly/Crepico"));
const SummaryCrepico = lazy(() => import("./components/Body/Store/Summary/Crepico"));
const SummaryPaygate = lazy(() => import("./components/Body/Store/Summary/Paygate"));
const InvoiceReceiptCrepico = lazy(() => import("./components/Body/Store/InvoiceReceipt/Crepico"));
const InvoiceReceiptPaygate = lazy(() => import("./components/Body/Store/InvoiceReceipt/Paygate"));
const DepositPaygate = lazy(() => import("./components/Body/Store/Deposit/Paygate"));
const DepositCrepico = lazy(() => import("./components/Body/Store/Deposit/Crepico"));
const Home = lazy(() => import("./components/Body/Home"));
const Confirm = lazy(() => import("./components/Body/Confirm"));
const NewAccount = lazy(() => import("./components/Body/NewAccount"));
const ApplicationComplete = lazy(() => import("./components/Body/ApplicationComplete"));
const Agreement = lazy(() => import("./components/Body/Agreement"));
const Signin = lazy(() => import("./components/Signin"));
const Signup = lazy(() => import("./components/Signup"));
const MailRegister = lazy(() => import("./components/RegisterMail"));
const Profile = lazy(() => import("./components/Body/Profile"));
const Application = lazy(() => import("./components/Body/Application"));
const ApplicationSteps = lazy(() => import("./components/Body/ApplicationSteps"));
const Store = lazy(() => import("./components/Body/Store"));
const StoreEdit = lazy(() => import("./components/Body/Store/Edit"));
const Notification = lazy(() => import("./components/Body/Store/Notification"));
const Calendar = lazy(() => import("./components/Body/Store/Notification/Calendar"));
const Paygate = lazy(() => import("./components/Body/Store/Notification/Paygate"));
const Rules = lazy(() => import("./components/Body/Store/Notification/Rules"));
const Contact = lazy(() => import("./components/Body/Contact"));
const ContactCompleted = lazy(() => import("./components/Body/Contact/ContactCompleted"));
const AdminStore = lazy(() => import("./components/Body/AdminStore"));
const AdminDeposit = lazy(() => import("./components/Body/AdminStore/AdminDeposit"));
const AdminDepositDetail = lazy(() => import("./components/Body/AdminStore/AdminDeposit/AdminDepositDetail"));
const AdminInvoiceReceipt = lazy(() => import("./components/Body/AdminStore/AdminInvoiceReceipt"));
const AreaSetting = lazy(() => import("./components/Body/AdminStore/AreaSetting"));
const AdminNotification = lazy(() => import("./components/Body/AdminStore/Notification"));
const AdminCalendar = lazy(() => import("./components/Body/AdminStore/Notification/Calendar"));
const AdminPaygate = lazy(() => import("./components/Body/AdminStore/Notification/Paygate"));
const AdminRules = lazy(() => import("./components/Body/AdminStore/Notification/Rules"));
const ResetPassword = lazy(() => import("./components/Body/ResetPassword"));
const ForgotPassword = lazy(() => import("./components/Body/ForgotPassword"));
const Error = lazy(() => import("./components/Body/ErrorPage"));
const Document = lazy(() => import("./components/Body/Document"));
const DocumentCompleted = lazy(() => import("./components/Body/Document/DocumentCompleted"));
const ChangePassword = lazy(() => import("./components/Body/ChangePassword"));
const AdminInvoiceMonthly = lazy(() => import("./components/Body/AdminStore/AdminInvoiceReceipt/AdminInvoiceMonthly"));
const MonthlyCostByStore = lazy(() => import("./components/Body/AdminStore/AdminInvoiceReceipt/AdminInvoiceMonthly/MonthlyCostByStore"));
const CreditCardMonthlyFee = lazy(() => import("./components/Body/AdminStore/AdminInvoiceReceipt/AdminInvoiceMonthly/CreditCardMonthlyFee"));
const AdminReceiptMonthly = lazy(() => import("./components/Body/AdminStore/AdminInvoiceReceipt/AdminReceiptMonthly"));
const RejectedPage = lazy(() => import("./components/Body/RejectedPage"));
const SendMailSuccess = lazy(() => import("./components/RegisterMail/SendMailSuccess"));
const AccessDenied = lazy(() => import("./components/Body/AccessDenied"));
const GMOPayment = lazy(() => import("./components/Body/GMOPayment"));
const RegisterMemberCard = lazy(() => import("./components/Body/GMOPayment/Register_Member_Card"));
const GMOPaymentSuccess = lazy(() => import("./components/Body/GMOPayment/GMOPaymentSuccess"));
const Terms = lazy(() => import("./components/Body/Terms"));
const CrepicoTerms = lazy(() => import("./components/Body/CrepicoTerms"));

const AppRouter = () => {

    return (
        <Router>
            <Routes>
                <Route path="/" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <Home />
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/home" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <Home />
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/confirm" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[APPLICATION, APPLICATION_COMPLETE, APPLICATION_CANCEL]}>
                            <Confirm />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/new-account" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[APPLICATION, APPLICATION_COMPLETE, APPLICATION_CANCEL]}>
                            <NewAccount />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/application-complete" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[APPLICATION, APPLICATION_STEPS, APPLICATION_COMPLETE]}>
                            <ApplicationComplete />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/agreement" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <Agreement />
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/signin" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <Signin />
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/forgot-password" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ForgotPassword />
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/signup/:type/:token" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <Signup />
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/mail/register" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <MailRegister />
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/send-mail-success" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <SendMailSuccess />
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/profile" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[]}>
                            <Profile />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/reset-password/:token" exact element={<Fragment>
                    <Suspense fallback={null}>
                        <ResetPassword />
                    </Suspense>
                </Fragment>} />
                <Route path="/application" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[APPLICATION]}>
                            <Application />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/application-steps" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[APPLICATION, APPLICATION_STEPS]}>
                            <ApplicationSteps />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <Store />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-edit" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE, APPLICATION_ACCEPTED_CORRECTIONS]}>
                            <StoreEdit />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-deposit" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <CheckDeposit />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-deposit/paygate" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <DepositPaygate />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-deposit/crepico" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <DepositCrepico />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-deposit/paygate/detail/:merchantNo/:transactionType/:datetime" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <DepositDetailPaygate />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-deposit/crepico/detail/:merchantNo/:transactionType/:datetime" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <DepositDetailCrepico />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-deposit/paygate/detail/:paymentDate" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <DepositDetailAllPaygate />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-deposit/crepico/detail/:paymentDate" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <DepositDetailAllCrepico />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/summary/paygate" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <SummaryPaygate />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/summary/crepico" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <SummaryCrepico />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/summary-monthly/paygate" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <SummaryMonthlyPaygate />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/summary-monthly/crepico" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <SummaryMonthlyCrepico />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-invoice-receipt" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <CheckInvoiceReceipt />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-invoice-receipt/paygate" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <InvoiceReceiptPaygate />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-invoice-receipt/crepico" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <InvoiceReceiptCrepico />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-invoice-receipt/paygate/invoice-monthly/:invoiceNo" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <InvoiceMonthlyPaygate />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-invoice-receipt/crepico/invoice-monthly/:invoiceNo" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <InvoiceMonthlyCrepico />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-invoice-receipt/paygate/receipt-monthly/:invoiceNo" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <ReceiptMonthlyPaygate />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-invoice-receipt/crepico/receipt-monthly/:invoiceNo" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <ReceiptMonthlyCrepico />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-invoice-receipt/paygate/invoice/:invoiceNo" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <InvoicePaygate />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-invoice-receipt/crepico/invoice/:invoiceNo" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <InvoiceCrepico />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-invoice-receipt/paygate/receipt/:invoiceNo" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <ReceiptPaygate />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-invoice-receipt/crepico/receipt/:invoiceNo" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <ReceiptCrepico />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-crepico-payment" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <StoreCrepicoPayment />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-notification" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <Notification />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-notification/calendar" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <Calendar />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-notification/paygate" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <Paygate />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-notification/rules" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <Rules />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-notification/rules/paygate" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <RulesPaygate />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-notification/rules/crepico" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <RulesCrepico />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-notification/rollpaper" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <Rollpaper />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-notification/faq" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <Faq />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/store-support" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE]}>
                            <Support />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/contact" exact element={<ContactLayout>
                    <Suspense fallback={null}>
                        <Contact />
                    </Suspense>
                </ContactLayout>} />
                <Route path="/contact-completed" exact element={<ContactLayout>
                    <Suspense fallback={null}>
                        <ContactCompleted />
                    </Suspense>
                </ContactLayout>} />
                <Route path="/document" exact element={<ContactLayout>
                    <Suspense fallback={null}>
                        <Document />
                    </Suspense>
                </ContactLayout>} />
                <Route path="/document-completed/:type" exact element={<ContactLayout>
                    <Suspense fallback={null}>
                        <DocumentCompleted />
                    </Suspense>
                </ContactLayout>} />
                <Route path="/admin-store" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminStore />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/area-setting" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AreaSetting />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-deposit" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminDeposit />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-deposit/admin-deposit-detail/:transferDate" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminDepositDetail />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-deposit/admin-deposit-detail/:transferDate/:transactionType/:merchantNo" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminDepositDetail />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-crepico-payment" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminStoreCrepicoPayment />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-notification" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminNotification />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-invoice-receipt" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminInvoiceReceipt />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-invoice-receipt/admin-invoice-monthly/:yearMonth" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminInvoiceMonthly />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-invoice-receipt/admin-invoice-monthly/credit-card-monthly-fee/:month/:type" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <CreditCardMonthlyFee />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-invoice-receipt/admin-invoice-monthly/monthly-cost-by-store/:month/:no" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <MonthlyCostByStore />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-invoice-receipt/admin-receipt-monthly/:yearMonth" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminReceiptMonthly />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-notification/calendar" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminCalendar />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-notification/paygate" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminPaygate />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/admin-notification/rules" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[ADMIN]}>
                            <AdminRules />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/change-password" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[]}>
                            <ChangePassword />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/rejected" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[APPLICATION_REJECTED]}>
                            <RejectedPage />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/gmo-payment" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[APPLICATION, APPLICATION_COMPLETE, STORE, ADMIN]}>
                            <GMOPayment />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/gmo-payment/register/:token" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[APPLICATION, APPLICATION_COMPLETE, STORE, ADMIN]}>
                            <RegisterMemberCard />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                <Route path="/gmo-payment-success" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <ProtectedRoute permission={[STORE, ADMIN]}>
                            <GMOPaymentSuccess />
                        </ProtectedRoute>
                    </Suspense>
                </DefaultLayout>} />
                
                <Route path="/terms" exact element={<ContactLayout>
                    <Suspense fallback={null}>
                        <Terms />
                    </Suspense>
                </ContactLayout>} />

                <Route path="/new-terms" exact element={<ContactLayout>
                    <Suspense fallback={null}>
                        <CrepicoTerms />
                    </Suspense>
                </ContactLayout>} />

                <Route path="/access-denied" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <AccessDenied />
                    </Suspense>
                </DefaultLayout>} />

                <Route path="*" exact element={<DefaultLayout>
                    <Suspense fallback={null}>
                        <Error />
                    </Suspense>
                </DefaultLayout>} />
            </Routes>
        </Router>
    );
};

export default AppRouter;
