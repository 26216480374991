import { Navigate } from 'react-router-dom';
import { checkExpToken } from './constants/checkExpToken.constant';
import _ from 'lodash';
import { authSelector } from './redux/auth/selector';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children, permission }) => {
    const { permissionPage } = useSelector(authSelector);

    const checkCheckPermission = () => {
        if(permission?.length === 0) {
            return true;
        }

        let check = false;
        _.forEach(permission, function (element) {
            if (permissionPage === element) {
                check = true;
            }
        });
        return check;
    }

    const renderComponent = () => {
        const stateToken = checkExpToken();
        let check = checkCheckPermission();
        if (stateToken) {
            return check ? children : <Navigate to="/access-denied" replace />
        } else {
            // return check ? children : <Navigate to="/" replace />;
            return <Navigate to="/" replace />;
        }
    }


    return renderComponent();
};

export default ProtectedRoute;