import API_ENDPOINT from "../../constants/api_endpoint.constant";
import axios from '../../utils/axiosService';

export default class AdminInvoiceMonthlyService {
    
    getData(merchantNo, yearMonth) {
        return axios.get(`${API_ENDPOINT.ADMIN_INVOICE_MONTHLY}?agxMerchantNo=${btoa(merchantNo)}&yearMonth=${yearMonth}`);
    };

    getDataFormCreditCardMonthlyFee(merchantNo, yearMonth, type) {
        return axios.get(`${API_ENDPOINT.ADMIN_INVOICE_MONTHLY}/${API_ENDPOINT.CREDIT_CARD_MONTHLY_FEE}?agxMerchantNo=${btoa(merchantNo)}&yearMonth=${yearMonth}&type=${type}`);
    }

    getDataFormMonthlyCostByStore(merchantNo, yearMonth) {
        return axios.get(`${API_ENDPOINT.ADMIN_INVOICE_MONTHLY}/${API_ENDPOINT.MONTHLY_COST_BY_STORE}?agxMerchantNo=${merchantNo}&yearMonth=${yearMonth}`);
    }
}