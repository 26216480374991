import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function ContactLayout({ children }) {
    return (
        <div className="contact-body">
            <Header />
            {children}
            <Footer />
        </div>
    );
}

export default ContactLayout;