import React, { useEffect } from 'react';
import { Link, navigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import './index.css';
import { authSelector } from '../../../../../redux/auth/selector';
import appSlice from '../../../../../redux/app/index';
import { useNavigate  } from 'react-router-dom';

function Rollpaper() {
    const { merchantNo } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { agxStoreName, agxMerchantNo, agxNewMerchantNo, user } = useSelector(authSelector);
    useEffect(() => {
        dispatch(appSlice.actions.changeUrlBanner("/store"));
    }, [dispatch]);

    return (
        <div className="wrapper-body">
            <div className="container">
                <div className="page-heading">
                    <ul className="breadcrumb">
                        <li>
                            <Link to="/store" title="チョキペイ">チョキペイ</Link>
                        </li>
                        <li>
                            <Link to="/store-notification" title="その他">その他</Link>
                        </li>
                        <li>ロール紙の購入 {'>'} ストアの選択</li>
                    </ul>
                </div>
                <div className="row align-items-center">
                    <div className="menu-item col-sm-6 center-block pb-0">
                        <a id="paygate" href="https://station.paygate.ne.jp/rollpaper/" target="_blank">PAYGATE</a>
                        <div className='h-support'>
                        </div> 
                    </div>
                    <div className="menu-item col-sm-6 center-block pb-0">
                        <a id="crepico" href="https://www.seiko-sol.co.jp/products/crepico/crepico_user/user_order/" target="_blank">CREPICO</a>
                        <div className='h-support'>
                            <div className='color-13AE9C mt-2'>※ CREPICO サポートサイトへのアクセスは下記を入力してください。</div>
                            <div className='d-flex color-13AE9C'>
                                <p className='w-25'>ユーザー名(ID)<span className='float-inline-end'>:</span></p>
                                <p>&nbsp;&nbsp;&nbsp;cp-user</p>
                            </div>
                            <div className='d-flex color-13AE9C'>
                                <p className='w-25'>パスワード<span className='float-inline-end'>:</span></p>
                                <p>&nbsp;&nbsp;&nbsp;cp-user1</p>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Rollpaper;