import jwt_decode from "jwt-decode";
import { getToken } from '../utils/token';

export const checkExpToken = (token = getToken()) => {
    if (token) {
        let date = new Date();
        const decodeToken = jwt_decode(token);
        if (decodeToken.exp < date.getTime() / 1000) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
}