import API_ENDPOINT from "../constants/api_endpoint.constant";
import axios from '../utils/axiosService';
import { removeToken } from '../utils/token';


export default class AuthService {
    signIn({ username = "", password = "" }) {
        return axios.post(API_ENDPOINT.SIGNIN, {
            username,
            password
        });
    }
    logout() {
        removeToken();
        // localStorage.clear();
        // localStorage.removeItem('access_token');
        // localStorage.removeItem('persist:root');
    };
    checkAuth() {
        return axios.post(API_ENDPOINT.CHECK_AUTH);
    };
    signUp(info) {
        return axios.post(API_ENDPOINT.SIGNUP,
            info
        );
    };
    changeInfo(request) {
        return axios.post(API_ENDPOINT.CHANGE_INFO,
            request
        );
    };
    changePassword(request) {
        return axios.post(API_ENDPOINT.CHANGE_PASSWORD,
            request
        );
    };
}
