import API_ENDPOINT from "../constants/api_endpoint.constant";
import axios from '../utils/axiosService';

export default class ApplicationService {
    create(merchant) {
        return axios.post(API_ENDPOINT.MERCHANT, merchant);
    };

    update({ merchant, agxMerchantNo, isSendMail }) {
        return axios.put(`${API_ENDPOINT.MERCHANT}/${btoa(agxMerchantNo)}?isSendMail=${isSendMail}`, merchant);
    }

    getData(agxMerchantNo) {
        return axios.get(`${API_ENDPOINT.MERCHANT}/element/application-steps?merchantNo=${btoa(agxMerchantNo)}`);
    }
}