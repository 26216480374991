import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    location: '/admin',
    urlBanner: '/home',
}
export default createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {
        clearState: () => initialState,
        changeUrlBanner: (state, action) => {
            state.urlBanner = action.payload
        },
        changeSignout: (state, action) => {
            state.location = action.payload.location
            state.urlBanner = action.payload.urlBanner
        }
    },
    extraReducers: {}
})