import API_ENDPOINT from "../constants/api_endpoint.constant";
import axios from '../utils/axiosService';

export default class GMOService {
    getInfo(agxMerchantNo) {
        return axios.get(`${API_ENDPOINT.GMO_MEMBER}/info?agxMerchantNo=${btoa(agxMerchantNo)}`);
    };
    getLinkplusUrlPayment(agxMerchantNo) {
        return axios.post(`${API_ENDPOINT.GMO_PAYMENT}/linkplus-url-payment?agxMerchantNo=${btoa(agxMerchantNo)}`);
    }
    getLinkplusUrl(memberId, cardNo) {
        return axios.post(`${API_ENDPOINT.GMO_PAYMENT}/linkplus-url?memberId=${btoa(memberId)}&cardNo=${cardNo}`);
    }
}