import API_ENDPOINT from "../../constants/api_endpoint.constant";
import axios from '../../utils/axiosService';
import { getToken } from '../../utils/token';

axios.setHeader("Authorization", `Bearer ${getToken()}`);
axios.setHeader("Content-Type", `application/json`);
export default class InvoiceReceiptService {
    
    getAgxInvoiceService(merchantNo) {
        return axios.get(`${API_ENDPOINT.AGX_INVOICE}/user?agxMerchantNo=${btoa(merchantNo)}`);
    };
    getAgxInvoiceDetailService(merchantNo) {
        return axios.get(`${API_ENDPOINT.AGX_INVOICE_DETAIL}/user?agxMerchantNo=${btoa(merchantNo)}`);
    };
}