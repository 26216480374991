import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import appSlice from "../redux/app/index";
import authSlice from "../redux/auth/index";
import applicationSlice from "../redux/application/index";
import { removeToken } from '../utils/token';

const useClearState = () => {
    const dispatch = useDispatch();

    const handleClearState = useCallback(() => {
        removeToken();
        dispatch(authSlice.actions.handleClearState());
        dispatch(appSlice.actions.clearState());
        dispatch(applicationSlice.actions.clearState());
    }, [dispatch]);

    return {
        handleClearState
    }
}

export default useClearState;