import React from 'react';
import './App.css';
import AppRouter from './App.routes';
import { Provider } from "react-redux";
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { install } from 'resize-observer';

if (!window.ResizeObserver) {
  install();
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppRouter />
      </PersistGate>
    </Provider>
  );
}

export default App;
