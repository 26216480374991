import API_ENDPOINT from "../../constants/api_endpoint.constant";
import axios from '../../utils/axiosService';
import { getToken } from '../../utils/token';

axios.setHeader("Authorization", `Bearer ${getToken()}`);
axios.setHeader("Content-Type", `application/json`);

export default class AreaSettingService {
    // getDataAgxArea(merchantNo) {
    //     return axios.get(`${API_ENDPOINT.AGX_AREA}/user?agxMerchantNo=${merchantNo}`);
    // };
    // getDataAgxSubArea(merchantNo) {
    //     return axios.get(`${API_ENDPOINT.AGX_SUB_AREA}/user?agxMerchantNo=${merchantNo}`);
    // };

    getAllData(merchantNo) {
        return axios.get(`${API_ENDPOINT.AREA_SETTING}?agxMerchantNo=${btoa(merchantNo)}`);
    };

    createArea(area) {
        return axios.post(API_ENDPOINT.AGX_AREA, area)
    }

    updateArea(id, area) {
        return axios.put(`${API_ENDPOINT.AGX_AREA}/${id}`, area)
    }

    deleteArea(id) {
        return axios.delete(`${API_ENDPOINT.AGX_AREA}/${id}`)
    }

    createSubArea(subArea) {
        return axios.post(API_ENDPOINT.AGX_SUB_AREA, subArea)
    }

    updateSubArea(id, subArea) {
        return axios.put(`${API_ENDPOINT.AGX_SUB_AREA}/${id}`, subArea)
    }

    deleteSubArea(id) {
        return axios.delete(`${API_ENDPOINT.AGX_SUB_AREA}/${id}`)
    }
}