import { useState, useEffect } from 'react';
import axios from '../utils/axiosService';

// const useFetch = ({ url = '', page = 0, pageSize = 50 }) => {
//     const [data, setData] = useState(null);
//     const [totalPages, setTotalPages] = useState(0);
//     const [totalElements, setTotalElements] = useState(0);
//     const [loading, setLoading] = useState(true);

//     useEffect(() => {
//         axios.get(`${url}?page=${page}&pageSize=${pageSize}`)
//             .then((response) => {
//                 setData(response.data.data);
//                 setTotalPages(response.data.totalPages);
//                 setTotalElements(response.data.totalElements);
//                 setLoading(false);
//             }).catch((error) => {
//                 setData(null);
//                 setTotalPages(0);
//                 setTotalElements(0);
//                 setLoading(false);
//             });
//     }, [url, page, pageSize]);
    
//     return { data, totalPages, totalElements, loading }
// }
const useFetch = (url = '') => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`${url}`)
            .then((response) => {
                setData(response?.data?.data);
                setLoading(false);
            }).catch((error) => {
                setData(null);
                setLoading(false);
            });
    }, [url]);
    
    return { data, loading }
}

export default useFetch;