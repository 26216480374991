import { TOKEN_KEY_STORE } from "../constants/common.constant";

function setCookie(token = '') {
  const d = new Date();
  d.setTime(d.getTime() + (24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = TOKEN_KEY_STORE+"=" + token + ";" + expires + ";path=/";
}

function getCookie() {
  let name = TOKEN_KEY_STORE+'=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

function removeCookie() {
  document.cookie = TOKEN_KEY_STORE+'=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
}

export const getToken = () => {
  return sessionStorage.getItem(TOKEN_KEY_STORE);
  // return localStorage.getItem(TOKEN_KEY_STORE);
  // return getCookie();
};

export const setToken = (token) => {
  sessionStorage.setItem(TOKEN_KEY_STORE, token);
  // localStorage.setItem(TOKEN_KEY_STORE, token);
  // setCookie(token);
};

export const removeToken = () => {
  sessionStorage.removeItem(TOKEN_KEY_STORE);
  // localStorage.removeItem(TOKEN_KEY_STORE);
  // removeCookie();
};
